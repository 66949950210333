import loadable from '@loadable/component'

const ArrowCircleDown = loadable(() => import('@vhl/icons'), {
  resolveComponent: component => component.ArrowCircleDown,
})

const ArrowDown = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className="flex items-end justify-end px-[30px] mb-[24px] flex-grow">
      <span className="animate-bounce cursor-pointer" onClick={onClick}>
        <ArrowCircleDown
          className="h-15 w-15 desktop:w-19 desktop:h-19"
          fill="none"
        />
      </span>
    </div>
  )
}

export default ArrowDown
